import { Grid, IconButton, Typography, Link as MUILink } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "next-i18next";

import Link from "../Link";

const CommunicationBanner = ({ dismissible, onClose }) => {
  const { t } = useTranslation("common");

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justifyContent={{ xs: "center" }}
      spacing={3}
      px={6}
      py={2}
      sx={(theme) => ({
        backgroundColor: theme.palette.alternate.dark,
        color: theme.palette.text.primary,
      })}
    >

      {dismissible && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.text.secondary,
          })}
        >
          <CloseIcon />
        </IconButton>
      )}

      <Grid item>
        <Typography variant="subtitle2" component="span">
          {t("communication_banner.text")}
        </Typography>
      </Grid>
      <Grid item>
        <MUILink
          sx={{ fontWeight: 600 }}
          component={Link}
          variant="subtitle2"
          href="/resources/livre-blanc-budget-participatif"
          endIcon={(
            <svg
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          )}
        >
          {t("communication_banner.cta")}
        </MUILink>
      </Grid>
    </Grid>
  )
}

export default CommunicationBanner;
