import React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import Link from "../Link";

const ButtonWithSubMenu = ({ item, activeLinkStyle }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        size="large"
        sx={{
          color: theme.palette.text.primary,
          fontWeight: 400,
        }}
        onClick={handleOpen}
        onMouseEnter={handleOpen}
        aria-haspopup="true"
        aria-controls={`sub-menu-${item.sub_menu.key}`}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
      >
        {item.title}
      </Button>
      <Menu
        id={`sub-menu-${item.sub_menu.key}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        keepMounted
      >
        {item.sub_menu.items.map((subItem, j) => (
          <MenuItem
            onClick={handleClose}
            key={j}
            component={Link}
            href={subItem.href}
            sx={{
              paddingY: 1.5,
              paddingX: 3,
              ...activeLinkStyle(subItem.href),
            }}
          >
            {subItem.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonWithSubMenu;
