import React from "react";
import { useRouter } from "next/dist/client/router";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "next-i18next";

import Container from "../Container";
import Link from "../Link";
import navigation from "./navigation";
import ButtonWithSubMenu from "./ButtonWithSubMenu";
import fluicityLogo from "../../../public/static/icons/fluicity.svg";
import CloudinaryImage from "../CloudinaryImage";
import CommunicationBanner from "../CommunicationBanner";

const Navbar = () => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const { pathname } = useRouter();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [communicationBannerOpen, setCommunicationBannerOpen] = React.useState(true);
  const navItems = navigation(t);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeCommunicationBanner = () => {
    setCommunicationBannerOpen(false);
  }

  const activeLinkStyle = (href) => ({
    color: theme.palette.text.primary,
    backgroundColor:
      pathname === href ? theme.palette.alternate.main : "transparent",
  });

  return (
    <>
      {/** placeholder for the AppBar to count for the communicationBanner height **/}
      <Box marginTop={communicationBannerOpen ? { xs: 21, sm: 16 } : { xs: 6, sm: 9 }} />

      <AppBar
        position={"fixed"}
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
        elevation={1}
      >
        {communicationBannerOpen && <CommunicationBanner dismissible onClose={closeCommunicationBanner}/>}

        <Container paddingY={{ xs: 1, sm: 2 }}>
          <Box
            display="flex"
            justifyContent={{ sm: "space-between" }}
            alignItems="center"
            width="100%"
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              display="flex"
              sx={{ flexGrow: 1, justifyContent: { xs: "center", md: "left" } }}
            >
              <Box
                display="flex"
                component={Link}
                underline="none"
                href="/"
                title="Fluicity"
                width={150}
                height={34}
              >
                <CloudinaryImage
                  src={fluicityLogo}
                  width={150}
                  height={34}
                  alt="Fluicity logo"
                />
              </Box>
            </Box>
            <Box display={{ xs: "none", md: "block" }}>
              {navItems.map((item, i) => (
                <Box display="inline" marginX={1 / 2} key={i}>
                  {item.sub_menu ? (
                    <ButtonWithSubMenu
                      item={item}
                      activeLinkStyle={activeLinkStyle}
                    />
                  ) : (
                    <Button
                      size="large"
                      component={Link}
                      underline="none"
                      href={item.href}
                      sx={{ ...activeLinkStyle(item.href) }}
                    >
                      {item.title}
                    </Button>
                  )}
                </Box>
              ))}
              <Button
                href="/demo"
                variant="contained"
                color="primary"
                component={Link}
                size="large"
                sx={{ marginLeft: 1 }}
              >
                {t("navbar.cta")}
              </Button>
            </Box>
          </Box>
        </Container>
      </AppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              width: 260,
            },
          }}
        >
          <List>
            {navItems.map((item, i) => (
              <Box key={i}>
                {item.sub_menu ? (
                  <>
                    <ListItem>
                      <ListItemText primary={item.title} />
                    </ListItem>
                    <List component="div" disablePadding>
                      {item.sub_menu.items.map((subItem, j) => (
                        <ListItemButton
                          key={j}
                          component={Link}
                          href={subItem.href}
                          onClick={handleDrawerToggle}
                          sx={{
                            paddingLeft: 4,
                            ...activeLinkStyle(subItem.href),
                          }}
                        >
                          <ListItemText primary={subItem.title} />
                        </ListItemButton>
                      ))}
                    </List>
                  </>
                ) : (
                  <ListItemButton
                    component={Link}
                    href={item.href}
                    onClick={handleDrawerToggle}
                    sx={{ ...activeLinkStyle(item.href) }}
                  >
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                )}
              </Box>
            ))}
            <Box sx={{ marginTop: 2, textAlign: "center" }}>
              <Button
                href="/demo"
                variant="contained"
                color="primary"
                component={Link}
                size="large"
              >
                {t("navbar.cta")}
              </Button>
            </Box>
          </List>
        </Drawer>
      </Box>
    </>
  );
};

export default Navbar;
