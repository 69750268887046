const navigation = (t) => {
  return [
    {
      title: t("navbar.solutions"),
      sub_menu: {
        key: "features",
        items: [
          {
            title: t("navbar.features"),
            href: "/plateforme-consultation-citoyenne",
          },
          {
            title: t("navbar.call_for_proposals"),
            href: "/appel-a-propositions",
          },
          {
            title: t("navbar.participatory_budget"),
            href: "/budget-participatif",
          },
        ],
      },
    },
    { title: t("navbar.offers"), href: "/pricing" },
    {
      title: t("navbar.clients"),
      sub_menu: {
        key: "clients",
        items: [
          { title: t("navbar.publics"), href: "/publics" },
          { title: t("navbar.enterprises"), href: "/enterprises" },
          { title: t("navbar.partnerships"), href: "/partnerships" },
          { title: t("navbar.testimonials"), href: "/testimonials" },
        ],
      },
    },
    {
      title: t("navbar.about"),
      sub_menu: {
        key: "about",
        items: [
          { title: t("navbar.about_us"), href: "/about" },
          { title: t("navbar.our_expertise"), href: "/our-expertise" },
        ],
      },
    },
    { title: t("navbar.blog"), href: "/blog" },
    {
      title: t("navbar.resources_menu"),
      sub_menu: {
        key: "resources",
        items: [
          { title: t("navbar.ebooks-webinars"), href: "/ebooks-webinars" },
          { title: t("navbar.case-studies"), href: "/case-studies" },
        ],
      },
    },
  ];
};

export default navigation;
